import { Subscriber } from '../Subscriber';
import { empty } from '../observable/empty';
export function repeat(count = -1) {
  return source => {
    if (count === 0) {
      return empty();
    } else if (count < 0) {
      return source.lift(new RepeatOperator(-1, source));
    } else {
      return source.lift(new RepeatOperator(count - 1, source));
    }
  };
}
class RepeatOperator {
  constructor(count, source) {
    this.count = count;
    this.source = source;
  }
  call(subscriber, source) {
    return source.subscribe(new RepeatSubscriber(subscriber, this.count, this.source));
  }
}
class RepeatSubscriber extends Subscriber {
  constructor(destination, count, source) {
    super(destination);
    this.count = count;
    this.source = source;
  }
  complete() {
    if (!this.isStopped) {
      const {
        source,
        count
      } = this;
      if (count === 0) {
        return super.complete();
      } else if (count > -1) {
        this.count = count - 1;
      }
      source.subscribe(this._unsubscribeAndRecycle());
    }
  }
}
